@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&family=Oxygen:wght@300&family=Playfair+Display&family=Roboto&family=Roboto+Slab&display=swap');
.Description{
    margin-top: 5%;
    }
    .cards{
        /* margin: 50px 0 50px 0; */
        /* padding: auto 10px; */
        width: 300px;
        height: 25rem;
        display: flex;
        padding: 2px 10px;
        flex-direction: column;
        align-items:center;
        justify-content: space-evenly;
        background-color: rgba(255, 255, 255);
        border-radius: 12px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .card_header, .cards a {
        font-size: 20px;
        text-align: center;
    }
    .card_body{
        text-align: center;
    }
    /* .card_footer{
        background-color: #4b4b4b;
        display: flex;
    } */
    button{
        margin:0px 4px;
        padding:10px 16px;
        align-items: center;
        background-color: rgb(204,1,0);
        color: rgb(255, 255, 255);
        cursor: pointer;
        border-radius: 2px;
        border: none;
        font-family:Roboto, sans-serif;
        font-weight:500;
        font-size:14px;
        letter-spacing:0.5px;
        text-transform:uppercase;
    }
    button:hover{
        color: black;
    }
  .pic{
        width: 280px;
    }