.hero_container{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    height: 93vh;
    gap: 50px;
    background-color: rgb(67, 67, 67);
}
.des_container{
    align-self: center;
    padding-left: 4%;
}
.des_container h1{
    font-size: 80px;
    line-height: 75px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.des_container p{
    padding-top: 30px;
    padding-bottom: 40px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 18px;

}
.profile{
    align-items: center;
    margin: 0;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    border:1px solid  rgb(58, 56, 56);
    background-color: rgb(217, 217, 217);

}
.home_Btn{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}
@media screen and (max-width:1040px){
    .hero_container{
        gap: 30px;
    }
    .des_container h1{
        font-size: 60px;
        line-height: 60px;
    }
    .des_container p{
        font-size: 16px;
    
    }
}
@media screen and (max-width:770px){
    .hero_container{
        gap: 30px;
        padding-top:15%;
        padding-bottom: 5%;
    }
    .des_container h1{
        font-size: 40px;
        line-height: 50px;
    }
    .des_container p{
        padding-top: 20px;
        padding-bottom: 40px;
        font-size: 14px;
    
    }
}

