@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&family=Playfair+Display&family=Roboto+Slab&family=Roboto:wght@700&display=swap');

.affiliations{
    align-items: center;
    margin: 54px 0 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border:1px solid  rgb(58, 56, 56);
    background-color: rgb(217, 217, 217);

}

@media (max-width:960px){
    .card_container{
        grid-template-columns: auto auto;
    }
}

@media (max-width:766px){
    .card_container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .wrapper p{
        font-size: 18px;
    }
}
